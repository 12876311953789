.card-value-card {
  font: normal normal bold Roboto;
  font-size: 24px;
  letter-spacing: 0px;
  opacity: 1;
}

.card-label-card {
  font: normal normal bold Roboto;
  letter-spacing: 0px;
  font-size: 10px;
  opacity: 1;
}

.cards-align {
  font: normal normal bold Roboto;
  text-align: center;
  padding: 5px;
  color: white;
}

.card-bg-success {
  background: #35960a;
}
.card-bg-warning {
  background: #b6451d;
}
.card-bg-danger {
  background: #d0182b;
}

.summary-label-grap {
  font: normal normal bold Roboto;
  letter-spacing: 0px;
  opacity: 1;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.summary-progress-border {
  border-left: 1px solid rgb(235, 235, 235);
  border-bottom: 1px solid rgb(235, 235, 235);
  opacity: 1;
}

.measure-size {
  top: 1040px;
  left: 355px;
  width: 20px;
  height: 20px;
}

.tool-name {
  font: normal normal bold Roboto;
  letter-spacing: 0px;
  text-align: left;
  font-size: 12px;
  margin-left: 5px;
}

.progress-label {
  font-size: 14px;
  text-align: right;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  opacity: 1;
}

.percentage-value {
  font-size: 18px;
  text-align: left;
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;

  opacity: 1;
}

/* .loading-final {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  background-color: #ffffff;
  z-index: 99;
} */

.loading-final {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  background-color: #ffffff;
  z-index: 99;
}

.loading-lottie-final {
  position: absolute;
  top: 35%;
  left: 50%;
}

.green-notif {
  color: #25c6e3;
  background: white;
}

.yellow-notif {
  color: #f2e442;
  background: white;
}

.orange-notif {
  color: #b6451d;
  background: white;
}

.red-notif {
  color: #b42a19;
  background: white;
}

.aimhi-notif {
  color: #b42a19;
  background: white;
}

@media only screen and (max-width: 480px) {
  .loading-lottie {
    position: absolute;
    top: 50%;
    left: 15%;
  }
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;
  font-weight: bold;
}

.element-header {
  text-align: left;
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  color: #23303d;
  opacity: 1;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

/* flip animation */
/* .ai-icon {
  backface-visibility: visible !important;
  animation: flip 2s ease infinite;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
} */

/* hi-there animation */
/* .ai-icon {
  animation: hithere 1s ease infinite;
}
@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
} */

/* bounce animation */
.ai-icon {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.date-picker {
  /* width: 90%; */
  /* height: calc(1.5em + 0.5rem + 2px); */
  /* padding: 0.25rem 0.5rem; */
  /* font-size: 0.765625rem; */
  /* line-height: 1.5; */
  width: 100%;
  font-size: 14px;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #3a7d21;
  border: 1px solid #23303d;
  text-align: center;
  padding: 0px;
  flex: 1;
}

.datepicker-placeholder::placeholder {
  color: #fff; /* Replace 'red' with the color you want */
}

/* @keyframes pulse-animation {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(180, 42, 25, 0.7);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(180, 42, 25, 0);
  }
  100% {
    transform: scale(0.9);
  }
} */
