@-webkit-keyframes pulse {
  0% {
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.95;
    -webkit-transform: scale(1.015);
    transform: scale(1.015);
  }
  100% {
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  50% {
    opacity: 0.95;
    transform: scale(1.015);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}

.shimmer {
  -webkit-animation: pulse 1.8s infinite cubic-bezier(0.4, 0, 0.6, 1);
  animation: pulse 1.8s infinite cubic-bezier(0.4, 0, 0.6, 1);
  will-change: opacity, transform;
  transform-origin: center;
  contain: layout paint;
  box-sizing: border-box;
}

.no-scroll {
  overflow: hidden;
  width: 100%;
}
